import React from "react"

import { MainLayout } from "../layout"
import PageBody from "../components/LandingPage"

export default function Home() {
  return (
    <MainLayout>
      <PageBody />
    </MainLayout>
  )
}
